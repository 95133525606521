<template>
  <div class="header-big">
    <div class="MenuBar-Right">
      <MenuBar></MenuBar>
    </div>
    <div class="seh">
      <SearchBox></SearchBox>
    </div>
    <div id="lrc" class="lrc" v-show="lrcshow">{{ lrc }}</div>
    <div class="closelrc" @click="closelrc">关闭</div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import "../../node_modules/jquery-backstretch/jquery.backstretch.min.js";
import SearchBox from "./SearchBox";
import MenuBar from "./MenuBar.vue";

export default {
  name: "Header",
  components: {
    SearchBox,
    MenuBar
  },
  data() {
    return {
      lrcshow: false,
      lrc: ""
    };
  },
  mounted() {
    this.initSwiper();
    this.$eventBus.$on("currentLyrics", this.getLyrics);
  },
  beforeDestroy() {
    this.$eventBus.$off("currentLyrics", this.getLyrics);
  },
  methods: {
    getLyrics(lyrics) {
      this.lrc = lyrics;
    },
    closelrc() {
      this.lrcshow = !this.lrcshow;
    },
    initSwiper() {
      const imagePaths = [];
      for (let i = 1; i <= 105; i++) {
        imagePaths.push(`/assets/jpg/${i}.jpg`);
      }
      $(".header-big").backstretch(imagePaths, {
        start: 1,
        fade: 6000,
        duration: 3000
      });
    }
  }
};
</script>
<style scoped>
.header-big {
  width: 100vw;
  background-image: url("/public/assets/jpg/1.jpg");
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
  height: var(--header-height);
  min-height: 200px;
  max-height: 400px;
}

.seh {
  position: relative;
  top: calc(var(--header-height) * 0.33);
  width: 100%;
  pointer-events: auto;
  -webkit-user-drag: auto;
  z-index: 999999;
}

.header-big {
  pointer-events: none;
  -webkit-user-drag: none;
}

.MenuBar-Right {
  position: sticky;
  top: 0;
  z-index: 5;
  width: 500px;
  float: right;
  height: 30px;
  pointer-events: auto;
  -webkit-user-drag: auto;
  border-bottom: 0px solid #dd1f1f;
  background-color: rgba(255, 255, 255, 0);
}

.lrc {
  color: #0ef1f5;
  position: absolute;
  bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.closelrc {
  color: #ffffff;
  position: absolute;
  right: 0px;
  bottom: 0%;
  font-size: 20px;
  pointer-events: auto;
  -webkit-user-drag: auto;
  opacity: 0;
}

@media (max-width: 768px) {
  .header-big {
    height: 30vh !important;
  }

  .seh {
    top: calc(var(--header-height) * 0.25);
  }

  .lrc {
    color: #0ef1f5;
    position: absolute;
    bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .MenuBar-Right {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-big {
    height: 30vh;
  }

  .seh {
    top: 10vh;
  }
}
</style>
