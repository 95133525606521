<template>
  <div class="nav-bar-container" @click="sideshow">
    <div
      class="nav-bar-item"
      @click="scrollToCommon"
      :class="{ active: tablesle === '常用' }"
    >
      <i class="el-icon-suitcase"></i> <span>常用</span>
    </div>

    <div
      class="nav-bar-item"
      @click="scrollToNetWebsites"
      :class="{ active: tablesle === 'Ntion' }"
    >
      <i class="el-icon-coin"></i> <span>NET</span>
    </div>

    <div
      class="nav-bar-item"
      @click="scrollTomajorWebsites"
      :class="{ active: tablesle === '专业' }"
    >
      <i class="el-icon-help"></i> <span>专业</span>
    </div>

    <div
      class="nav-bar-item"
      @click="scrolldevelopWebsites"
      :class="{ active: tablesle === '编程' }"
    >
      <i class="el-icon-monitor"></i> <span>编程</span>
    </div>

    <div
      class="nav-bar-item"
      @click="scrollTotoolWebsites"
      :class="{ active: tablesle === '工具' }"
    >
      <i class="el-icon-setting"></i> <span>工具</span>
    </div>

    <div
      class="nav-bar-item"
      @click="scrollToinfoWebsites"
      :class="{ active: tablesle === '咨询' }"
    >
      <i class="el-icon-connection"></i> <span>咨询</span>
    </div>


  </div>
</template>

<script>
export default {
  name: "FootBar",

  data() {
    return {
      tablesle: "",
    };
  },
  methods: {
    sideshow() {
      this.$eventBus.$emit("show-side-bar");
    },
    scrollToCommon() {
      this.$eventBus.$emit("scroll-to-component", "commonWebsites");
      this.tablesle = "常用";
    },
    scrollToNetWebsites() {
      this.$eventBus.$emit("scroll-to-component", "NetWebsites");
      this.tablesle = "Ntion";
    },
    scrollTomajorWebsites() {
      this.$eventBus.$emit("scroll-to-component", "majorWebsites");
      this.tablesle = "专业";
    },
    scrolldevelopWebsites() {
      this.$eventBus.$emit("scroll-to-component", "developWebsites");
      this.tablesle = "编程";
    },
    scrollTotoolWebsites() {
      this.$eventBus.$emit("scroll-to-component", "toolWebsites");
      this.tablesle = "工具";
    },
    scrollToinfoWebsites() {
      this.$eventBus.$emit("scroll-to-component", "infoWebsites");
      this.tablesle = "咨询";
    },
    
  },
};
</script>

<style scoped>
.nav-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(253, 251, 251, 1);
  width: 100%;
  display: flex;
  height: var(--footer-height);
  font-weight: 900;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  transition: all 0.3s;
  z-index: 999;
}

.nav-bar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333232;
  flex: 1;
  padding: calc(var(--footer-height) * 0.1) 0;
}

.nav-bar-container .nav-bar-item.active {
  color: #ff5500;
}

.nav-bar-container .nav-bar-item > i {
  font-size: calc(var(--footer-height) * 0.4);
  margin-bottom: calc(var(--footer-height) * 0.05);
}

.nav-bar-container .nav-bar-item > span {
  font-size: calc(var(--footer-height) * 0.25);
  font-weight: 700;
}

/* 响应式布局 */
/* 大屏设备 (>= 1200px) */
@media (min-width: 1200px) {
  .nav-bar-container .nav-bar-item > i {
    font-size: calc(var(--footer-height) * 0.42);
  }
  
  .nav-bar-container .nav-bar-item > span {
    font-size: calc(var(--footer-height) * 0.3);
  }
}

/* 平板设备 (768px - 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .nav-bar-container .nav-bar-item > i {
    font-size: calc(var(--footer-height) * 0.38);
  }
  
  .nav-bar-container .nav-bar-item > span {
    font-size: calc(var(--footer-height) * 0.28);
  }
}

/* 手机设备 (< 768px) */
@media (max-width: 767px) {
  .nav-bar-container .nav-bar-item > i {
    font-size: calc(var(--footer-height) * 0.35);
    margin-bottom: 0.2rem;
  }
  
  .nav-bar-container .nav-bar-item > span {
    font-size: calc(var(--footer-height) * 0.25);
  }
}

/* 横屏模式特殊处理 */
@media (orientation: landscape) and (max-height: 600px) {
  .nav-bar-container .nav-bar-item > i {
    font-size: calc(var(--footer-height) * 0.15);
    margin-bottom: 0.1rem;
  }
  
  .nav-bar-container .nav-bar-item > span {
    font-size: calc(var(--footer-height) * 0.1);
  }
}

/* iPhone X 及以上全面屏机型适配 */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .nav-bar-container {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>
