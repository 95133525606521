<template>
  <div class="urlteam">
    <div class="text-gray" >
      <i class="el-icon-s-unfold tag-inco"></i>
     <span @click="handleClick">{{ title }}</span> 
    </div>

    <div class="row">
      <div class="url-card" v-for="(item, index) in weblist" :key="index" >
        <div class="url-body">
          <a :href="item.mainurl" target="_blank">
            <div class="card-body">
              <div class="url-img">
                <img :src="'/assets/ico/'+item.imgname" />
              </div>
              <div class="url-info">
                <div class="url-name">
                  <strong>{{ item.name }}</strong>
                </div>
                <p class="text-muted">{{ item.mainname }}</p>
              </div>
            </div>
          </a>
          <a :href="item.url" target="_blank">
            <i class="el-icon-video-play url-inco"> </i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    inco:{
      type: String,
      default: '直达',
    },
    title: {
      type: String,
      default: '常用网站',
    },
    weblist: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick() {
      if (window.innerWidth >= 780) { 
      this.$emit('click', this.title, this.weblist);
    }   
    },
  },
};
</script>
  
<style  scoped>

.text-muted {
  overflow: hidden;
  width: 7.5rem;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;  /* Opera */
  -ms-text-overflow: ellipsis; /* IE */
  word-break: break-all;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: box !important;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
}

.text-gray {
  color: #121111;
  margin-left: 0.625rem;
  display: flex;
  font-weight: 600;
  font-size: 1.125rem;
  align-items: center;
  margin-bottom: -0.3125rem;
  margin-top: 0.3125rem;
}

.tag-inco {
  font-size: 1.25rem;
  margin-right: 0.3125rem;
}

.row {
  width: 98%;
  margin-left:1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 0px solid #d42828;
}

.url-card {
  flex-basis: 15%;    /* 默认在大屏幕下显示6列 */    
  margin: 0.8%;  
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;
}

.url-card .url-body {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.url-card .url-body {
  font-size: 75%;
  background-color: #f9f9f9;
  color: #efe9e9;
  border-radius: 5px;
  transition: 0.3s;
}

.url-card .url-body:hover {
  transform: translateY(-0.375rem);
  -webkit-transform: translateY(-0.375rem);
  -moz-transform: translateY(-0.375rem);
  box-shadow: 0 0.8125rem 1.25rem -0.75rem rgba(0, 36, 100, 0.3);
  -webkit-box-shadow: 0 0.8125rem 1.25rem -0.75rem rgba(0, 36, 100, 0.3);
  -moz-box-shadow: 0 0.8125rem 1.25rem -0.75rem rgba(0, 36, 100, 0.3);
}

.url-inco {
  color: #000;
  position: fixed;
  font-size: 1.25rem;
  right: 0.625rem;
  top: 1.75rem;
  opacity: 0.2;
}

.url-inco:hover {
  color: #e11d1d;
}

.url-body {
  padding: 0.9375rem;
  border: 0px solid #d42831;
}

.url-card .url-name:hover {
  overflow: hidden;
  padding-right: 0.3125rem;
  color: #e90d0d;
}

.card-body {
  display: flex;
  align-items: center;
}

.url-card .url-img {
  width: 3.125rem;
  height: 3.125rem;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
  margin-right: 0.625rem;
}

.url-card .url-img {
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
}

.url-card .url-img > img {
  max-height: 100%;
  vertical-align: unset;
}

a {
  color: #282a2d;
  outline: 0 !important;
  text-decoration: none;
}

p {
  color: #1d232b;
  margin: 0;
  white-space: normal;
  word-break: break-all;
}

@media screen and (max-width: 48em) {
  .content-site {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0px solid #d42828;
  }

  .text-muted {
    overflow: hidden;
    width: 5rem;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: box !important;
    -webkit-line-clamp: 1;
    -moz-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
  }

  .url-card {
    flex-basis: 45%;  /* 在小屏幕（手机）下显示2列 */
    margin: 2.5%;
    margin-bottom: 1%;
  }

  .url-body {
    padding: 0.5rem;
    border: 0px solid #d42831;
  }

  .url-card .url-name:hover {
    overflow: hidden;
    padding-right: 0.3125rem;
    color: #e90d0d;
  }

  .card-body {
    display: flex;
    align-items: center;
  }

  .url-card .url-img {
    width: 1.875rem;
    height: 1.875rem;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    background: rgba(255, 255, 255, 0);
    overflow: hidden;
    margin-right: 0.3125rem;
  }

  .url-card .url-img > img {
    max-height: 100%;
    vertical-align: unset;
  }

  .url-card .url-body {
    font-size: 75%;
    background-color: #f9f9f9;
    color: #efe9e9;
    border-radius: 5px;
    transition: 0.3s;
  }

  .url-card .url-body:hover {
    transform: translateY(-0.375rem);
    -webkit-transform: translateY(-0.375rem);
    -moz-transform: translateY(-0.375rem);
    box-shadow: 0 0.8125rem 1.25rem -0.75rem rgba(0, 36, 100, 0.3);
    -webkit-box-shadow: 0 0.8125rem 1.25rem -0.75rem rgba(0, 36, 100, 0.3);
    -moz-box-shadow: 0 0.8125rem 1.25rem -0.75rem rgba(0, 36, 100, 0.3);
  }

  .url-inco {
    color: #000;
    position: relative;
    font-size: 1.25rem;
    float: right;
    right: -0.3125rem;
    top: -1.4375rem;
  }

  .url-inco:hover {
    color: #e11d1d;
  }
}

@media screen and (min-width: 48em) and (max-width: 64em) {
  .url-card {
    flex-basis: 23%;  /* 在中等屏幕（平板）下显示4列 */
    margin: 1%;
    margin-bottom: 1%;
  }
}

/* 新增大屏幕断点 */
@media screen and (min-width: 64em) and (max-width: 80em) {
  .url-card {
    flex-basis: 18%;  /* 在较大屏幕下显示5列 */
    margin: 1%;
    margin-bottom: 1%;
  }
}

/* 手机端 - 小屏幕 (< 480px) */
@media screen and (max-width: 30em) {
  .url-card {
    flex-basis: 45%;  /* 2列显示 */
    margin: 2.5%;
  }
  
  .url-card .url-img {
    width: 1.875rem;
    height: 1.875rem;
  }
}

/* 手机端 - 中等屏幕 (480px - 768px) */
@media screen and (min-width: 30em) and (max-width: 48em) {
  .url-card {
    flex-basis: 31%;  /* 3列显示 */
    margin: 1%;
  }
}

/* 平板端 (768px - 1024px) */
@media screen and (min-width: 48em) and (max-width: 64em) {
  .url-card {
    flex-basis: 23%;  /* 4列显示 */
    margin: 1%;
  }
}

/* 小型桌面端 (1024px - 1280px) */
@media screen and (min-width: 64em) and (max-width: 80em) {
  .url-card {
    flex-basis: 18%;  /* 5列显示 */
    margin: 1%;
  }
}

/* 大型桌面端 (>= 1280px) */
@media screen and (min-width: 80em) {
  .url-card {
    flex-basis: 15%;  /* 6列显示 */
    margin: 0.8%;
  }
}

/* iPad Mini & iPad 9.7" 竖屏 */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) {
  .url-card {
    flex-basis: 31%;  /* 3列显示 */
    margin: 1%;
  }
  
  .url-card .url-img {
    width: 2.5rem;
    height: 2.5rem;
  }
}

/* iPad Mini & iPad 9.7" 横屏 */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) {
  .url-card {
    flex-basis: 23%;  /* 4列显示 */
    margin: 1%;
  }
}

/* iPad Pro 10.5" */
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 834px) {
  .url-card {
    flex-basis: 23%;  /* 4列显示 */
    margin: 1%;
  }
}

/* iPad Pro 12.9" */
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) {
  .url-card {
    flex-basis: 18%;  /* 5列显示 */
    margin: 1%;
  }
}

/* 添加 -webkit-device-pixel-ratio 支持 */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 2) {
  .url-card {
    flex-basis: 31%;  /* 3列显示 */
    margin: 1%;
  }
}
</style>


  