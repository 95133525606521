<template>
  <div id="app" :style="{ height: windowHeight + 'px' }" class="contentarea">
    <div class="content-wrapper" ref="wrapper">  
      <Header></Header>      
      <ContentPage class="content-page"></ContentPage>
    </div>
    <BootMusic v-if="musicshow" class="boot-music"></BootMusic>
    <SideBar></SideBar>
      <FootBar class="foot-bar"></FootBar>
   
  </div>
</template>
<!-- eslint-disable -->
<script>

import Header from './components/Header.vue';
import ContentPage from './components/ContentPage.vue';
import FootBar from './components/FootBar.vue';
import SideBar from './components/SideBar.vue';
import BootMusic from './components/BootMusic.vue';

export default {
  name: 'App',
  components: {
    Header,
    ContentPage,
    FootBar,
    SideBar,
    BootMusic
  },
  data() {
    return {
      show: true,
      windowHeight: 0 ,
      musicshow: true    
    }
  },
  created() {
    this.$eventBus.$on('scroll-to-top', this.scrollTop);  
    this.$eventBus.$on('openmusic', this.openmusic);  
    
    this.$eventBus.$on('closemusic', this.closemusic);  
  },
  mounted() {
  this.windowHeight = window.innerHeight;
  
},
  beforeDestroy() {
    this.$eventBus.$off('scroll-to-top', this.scrollTop);
  },
  
  methods: {
    openmusic() {
      this.musicshow = true;
    },
    closemusic() {
      this.musicshow = false;
    },
    scrollTop() {
      this.$refs.wrapper.scrollTo({ top: 0, behavior: 'smooth' });
    },
     },  
}
</script>
<style >
:root {
  --header-height: 30vh;
  --footer-height: 6.5vh;
  --boot-music-height: 6.5vh;
  --content-padding: 15px;
}

/* 基础样式 */
body, html {
  position: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}

* {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

/* 布局容器 */
.contentarea {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgb(241, 240, 240);
  -webkit-user-select: none;
  user-select: none;
  height: -webkit-fill-available;
}

.content-wrapper {
  height: calc(100vh - var(--footer-height));
  padding-bottom: calc(var(--boot-music-height) + var(--footer-height));
  overflow-y: auto;
  width: 100%;
  background: linear-gradient(to right, rgb(241, 240, 240) 0%, rgb(221, 220, 220) 100%);
}

/* 固定定位元素 */
.boot-music {
  height: var(--boot-music-height);
  bottom: var(--footer-height);
  position: fixed;
  left: 0;
  z-index: 98;
}

.foot-bar {
  height: var(--footer-height);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding-bottom: 0;
}

/* 响应式布局 */

/* Safari 特定优化 */
@supports (-webkit-touch-callout: none) {
  .contentarea {
    min-height: -webkit-fill-available;
    position: fixed;
    overflow: hidden;
  }
  
  .content-wrapper {
    height: calc(100vh - var(--footer-height) - env(safe-area-inset-top));
    position: relative;
    transform: translateZ(0);
  }
  
  .foot-bar {
    padding-bottom: env(safe-area-inset-bottom, 0px);
    transform: translateZ(0);
  }
  
  .boot-music {
    bottom: calc(var(--footer-height) + env(safe-area-inset-bottom, 0px));
  }
  
  .content-page {
    padding-bottom: calc(var(--boot-music-height) + env(safe-area-inset-bottom, 0px)) !important;
  }
  
  /* iPhone 横屏模式优化 */
  @media (orientation: landscape) {
    .content-wrapper {
      height: calc(100vh - var(--footer-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
  }
}

/* 全局过渡效果 */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

/* 优化点击响应 */
.clickable {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
</style>

