import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [
    function (data) {
      let ret = '';
      for (let it in data) {
        ret +=
          encodeURIComponent(it) +
          '=' +
          encodeURIComponent(data[it]) +
          '&';
      }
      return ret;
    }
  ]
});

const upload = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

export { http, upload };