<template>
  <div class="container">
    <div class="close-btn">
      <el-button 
        type="text" 
        icon="el-icon-close" 
        @click="closeDialog" 
        class="close-icon"
      ></el-button>
    </div>

    <div class="header-section">
      <div style="width: 100%; display: flex; align-items: center;">
        <div class="til">文字粘贴</div>
        <el-button type="success"  class="loadfile" @click="saveContent" size="mini">保存内容</el-button>
      </div>
      <div class="editorcontainer">
      <vue-editor v-model="content" class="editor" :editor-toolbar="customToolbar"></vue-editor>
    </div>
    </div>

 

    <div class="file-section">
      <div style="width: 100%; display: flex; align-items: center;">
        <div class="til">文件传输</div>
        <el-button type="success" class="loadfile" @click="chooseFile" size="mini">选择文件</el-button>
      </div>

      <el-progress 
        v-if="progress > 0" 
        :percentage="progress"
        :format="format => `${format}% 上传中...`"
        status="success"
      ></el-progress>
      <input type="file" ref="fileInput" @change="uploadFile" style="display: none;" />

      <div class="table-container">
        <el-table 
          size="small" 
          :data="fileList" 
          highlight-current-row 
          border 
          style="width: 100%;"
          :header-cell-style="{ padding: '8px 0' }"
          :cell-style="{ padding: '8px 0' }"
          :class="{ 'is-mobile': isMobile }"
          height="100%">
          <el-table-column 
            align="center" 
            label="序号" 
            fixed="left"
            :width="isMobile ? 45 : 80">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          
          <el-table-column 
            prop="name" 
            label="文件名" 
            :show-overflow-tooltip="true"
            :min-width="isMobile ? 80 : 200">
          </el-table-column>
          
          <el-table-column 
            align="center" 
            prop="size" 
            label="大小" 
            class-name="file-size-column"
            :width="90">
            <template #default="scope">
              {{ formatFileSize(scope.row.size) }}
            </template>
          </el-table-column>
          
          <el-table-column 
            align="center" 
            label="操作" 
            :width="isMobile ? 110 : 200">
            <template slot-scope="scope">
              <div class="operation-buttons">
                <el-button 
                  type="primary" 
                  size="mini"
                  @click="down(scope.row.url)"
                  :class="{ 'is-mobile': isMobile }">
                  下载
                </el-button>
                <el-button 
                  type="danger" 
                  size="mini"
                  @click="deleteFile(scope.row)"
                  :class="{ 'is-mobile': isMobile }">
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "Notelist",
  components: {
    VueEditor
  },
  data() {
    return {
      fileList: [],
      progress: 0,
      content: '',
      isMobile: window.innerWidth <= 780,
      autoSaveTimer: null,
      customToolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],      
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
     
      ],
      saveTimeout: null,
      lastSavedContent: ''
    };
  },
  created() {
    this.$eventBus.$on("notenew", this.notenew);
  },
  beforeDestroy() {
    this.$eventBus.$off("notenew", this.notenew);
    window.removeEventListener('resize', this.checkDevice)
    if (this.autoSaveTimer) {
      clearInterval(this.autoSaveTimer);
    }
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }
  },

  mounted() {
    this.getFileList();
    this.getload();
    window.addEventListener('resize', this.checkDevice)
  },
  methods: {
    notenew() {
      this.getFileList();
      this.getload();
    },
    down(url) {
      window.open(url, "_blank");
    },

    async getload() {
      const response = await this.loadtext();
      this.content = response.data.content;
      this.lastSavedContent = this.content;
    },

    async saveContent() {
      //console.log(this.content);
      try {
        await this.savetext(this.content);
        this.lastSavedContent = this.content;
        this.$message.success('保存成功');
      } catch (error) {
        this.$message.error('保存失败');
      }
    },

    chooseFile() {
      this.$refs.fileInput.click();
    },
    async uploadFile(event) {
      try {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);

        const response = await this.savefile(formData, progressEvent => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        });

        const result = response.data.message;
        if (result === "文件上传成功") {
          this.progress = 0;
          this.getFileList();
        } else {
          alert(result);
          this.progress = 0;
        }
      } catch (error) {
        console.error(error);
        this.progress = 0;
      }
    },
    async deleteFile(row) {
      try {
        const response = await this.deletefile(row.name);
        const result = response.data.message;
        if (result === "文件删除成功") {
          this.getFileList();
        } else {
          alert(result);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getFileList() {
      const response = await this.getfile();

      var result = response.data;
      if (result == "没有找到文件记录") {
        this.fileList = "";
      } else {
        this.fileList = response.data.reverse();
      }
    },
    closeDialog() {
      this.$eventBus.$emit('close-note');  // 触发关闭对话框的事件
    },
    checkDevice() {
      this.isMobile = window.innerWidth <= 780
    },
    formatFileSize(bytes) {
      const size = Number(bytes);
      
      if (!size || isNaN(size)) return '0 MB';
      
      // 数据已经是 MB 单位，直接格式化
      return `${size.toFixed(2)} MB`;
    },
    debounceSave() {
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }
      
      if (this.content === this.lastSavedContent) {
        return;
      }

      this.saveTimeout = setTimeout(async () => {
        try {
          await this.saveContent();
        } catch (error) {
          console.error('自动保存失败:', error);
        }
      }, 1000);
    }
  },
  watch: {
    content: {
      handler(newVal) {
        if (newVal !== this.lastSavedContent) {
          this.debounceSave();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -var(--footer-height);
  width: 100%;
  height: calc(100vh - var(--footer-height)) !important;
  margin: 0;
  padding: clamp(8px, 2vw, 20px);
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  
  margin-bottom: clamp(8px, 2vh, 16px);
  height: 50%;
  overflow: hidden;
}

.editorcontainer {
  flex: 1;
  min-height: 0;
  margin: 12px 0;
  border: 1px solid #e4e7ed;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:deep(.ql-toolbar) {
  flex-shrink: 0;
  border-bottom: 1px solid #e4e7ed;
  background: #fff;
  z-index: 1;
}

:deep(.ql-container) {
  flex: 1;
  overflow-y: auto;
}

:deep(.ql-editor) {
  background-image: linear-gradient(#e4e7ed 1px, transparent 1px);
  background-size: 100% 2em;
  background-attachment: local;
  line-height: 2em;
  padding: 0.5em 0.5em;
  min-height: 100%;
}

:deep(.ql-editor p) {
  margin: 0;
  min-height: 2em;
}

.file-section {
  flex: 1;
  height: 50%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.table-container {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

.til {
  font-size: clamp(14px, 3vw, 20px);
  font-weight: 600;
  color: #303133;
  margin-bottom: clamp(8px, 2vh, 16px);
  position: relative;
  padding-left: 12px;
}

.til::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 18px;
  background: #20db7b;
  border-radius: 2px;
}

.loadfile {
  margin-left: 50%!important; 
  padding: clamp(4px, 1vw, 8px) clamp(8px, 2vw, 16px) !important;
  font-size: clamp(12px, 2vw, 14px) !important;
  border-radius: 6px !important;
  transition: all 0.3s ease;
}

.el-table {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
  font-size: 14px;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .file-size-column {
    min-width: 100px;
  }
}

.el-table td, .el-table th {
  padding: 12px 8px;
  text-align: center;
}

.el-button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.el-button--primary {
  background: #20db7b;
  border-color: #20db7b;
}

.el-button--primary:hover {
  background: #1cbd6a;
  border-color: #1cbd6a;
}

.el-button--danger:hover {
  opacity: 0.9;
}

.el-progress {
  margin: 16px 0;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 20px;
  z-index: 10;
  width: 30px;
  display: flex;
  justify-content: center;
}

.close-icon {
  font-size: 24px !important;
  color: #909399 !important;
  padding: 10px !important;
  transition: all 0.3s;
}

.close-icon:hover {
  color: #303133 !important;
  transform: rotate(90deg);
}

@media (max-width: 780px) {
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  .container {
    padding: 8px 12px 16px;
    height: calc(100vh - var(--footer-height) - env(safe-area-inset-bottom)) !important;
  }

  .editorcontainer {
    flex: 1;
    min-height: 200px;
    margin: 8px 0;
    border-radius: 4px;
  }

  .editor {
    height: 100%;
    min-height: 200px;
  }

  div[style*="height: 200px; overflow: auto;"] {
    flex: 1;
    min-height: 200px;
    margin-top: 12px;
    overflow: auto;
  }

  .el-table {
    margin-top: 8px;
    font-size: 12px !important;
  }

  div[style*="width: 100%; display: flex;"] {
    align-items: center;
    margin: 8px 0;
  }

  .til {
    font-size: 14px;
    margin-bottom: 0;
  }

  .el-button {
    padding: 3px 6px;
    font-size: 11px;
  }

  .el-progress {
    margin: 6px 0;
  }

  .close-btn {
    top: 8px;
    right: 20px;
    width: 30px;
  }

  .close-icon {
    font-size: 20px !important;
    padding: 6px !important;
  }

  .operation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 2px 0;
  }

  .operation-buttons .el-button--mini {
    height: 24px;
    line-height: 22px;
    padding: 0 8px !important;
    font-size: 12px !important;
    border-radius: 4px;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .operation-buttons .el-button--mini span {
    font-weight: 500;
    color: #fff;
    display: inline-block;
    line-height: 1;
  }

  .operation-buttons .el-button--primary.el-button--mini {
    background: #20db7b;
    border-color: #20db7b;
    min-width: 42px;
  }

  .operation-buttons .el-button--primary.el-button--mini:active {
    background: #1cbd6a;
    border-color: #1cbd6a;
    transform: scale(0.98);
  }

  .operation-buttons .el-button--danger.el-button--mini {
    background: #ff4d4f;
    border-color: #ff4d4f;
    min-width: 42px;
  }

  .operation-buttons .el-button--danger.el-button--mini:active {
    background: #d93638;
    border-color: #d93638;
    transform: scale(0.98);
  }

  .operation-buttons .el-button--mini span {
    font-weight: 500;
    color: #fff;
  }

  .operation-buttons .el-button--mini:active {
    opacity: 0.9;
    transition: all 0.2s ease;
  }

  .el-table-column--mini {
    padding: 4px !important;
  }

  .el-button.el-button--mini {
    padding: 4px 8px;
    font-size: 11px;
  }

  .table-container {
    height: calc(100vh - 380px);
    margin-top: 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .el-table {
    font-size: 12px !important;
  }

  .el-table::before {
    height: 0;
  }

  .el-table td, 
  .el-table th {
    padding: 6px 4px !important;
  }

  .el-table .el-table-column--index {
    width: 50px !important;
  }

  .el-table td[property="name"] {
    padding-left: 8px !important;
    max-width: 120px;
  }

  .operation-buttons {
    display: flex;
    gap: 4px;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .operation-buttons .el-button {
    min-width: auto;
    padding: 4px 8px !important;
    font-size: 12px !important;
  }

  .operation-buttons .el-button + .el-button {
    margin-left: 4px;
  }

  .el-table th {
    background-color: #f5f7fa !important;
    font-weight: 600;
    font-size: 12px;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(32, 219, 123, 0.1);
  }

  .table-container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .table-container::-webkit-scrollbar-thumb {
    background: #909399;
    border-radius: 2px;
  }

  .el-table .el-table__cell.is-center {
    text-align: center !important;
  }

  .header-section,
  .file-section {
    height: 45%;
  }
  
  .table-container {
    height: auto;
  }

  .el-table {
    .file-size-column {
      min-width: 80px;
      width: 80px;
    }
  }

  .loadfile {
    margin-right: 40px !important;
    padding: 3px 6px !important;
    font-size: 11px !important;
  }

  .table-container {
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  .el-table {
    width: 100% !important;
    table-layout: fixed;
  }

  .el-table /deep/ .el-table__header-wrapper,
  .el-table /deep/ .el-table__body-wrapper {
    width: 100% !important;
  }

  .el-table td[property="name"] {
    max-width: none;
  }

  .el-table__body-wrapper {
    overflow-x: hidden !important;
  }
}

input[type="file"] {
  display: none;
}

.operation-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-table td[property="name"] {
  text-align: left;
  padding-left: 16px;
}

.operation-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.operation-buttons .el-button {
  min-width: 70px;
}

@media (min-width: 781px) and (max-width: 1024px) {
  .container {
    padding: 12px 16px;
  }

  .table-container {
    height: calc(100vh - 400px);
  }

  .operation-buttons .el-button {
    min-width: 60px;
  }
}

@media (prefers-color-scheme: dark) {
  .container {
    background: #1a1a1a;
    color: #fff;
  }

  .til {
    color: #fff;
  }

  .el-table {
    background-color: #2c2c2c;
    border-color: #3a3a3a;
  }

  :deep(.ql-editor) {
    background-image: linear-gradient(rgba(255,255,255,0.1) 1px, transparent 1px);
  }
}

@media (prefers-color-scheme: dark) and (max-width: 780px) {
  .el-table th {
    background-color: #2c2c2c !important;
    color: #fff;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(32, 219, 123, 0.05);
  }

  .operation-buttons .el-button--primary.el-button--mini {
    background: #18b365;
    border-color: #18b365;
  }

  .operation-buttons .el-button--danger.el-button--mini {
    background: #d93638;
    border-color: #d93638;
  }

  .operation-buttons .el-button--mini:active {
    opacity: 0.8;
  }
}

/* 可选：自定义工具栏样式 */
.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0 0;
  border-color: #dcdfe6;
}

.ql-container.ql-snow {
  border-radius: 0 0 4px 4px;
  border-color: #dcdfe6;
}

.ql-editor {
  min-height: 200px;
}
</style>
